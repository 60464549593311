body {
  background-color: #E0F2E9; /* Lighter green background */
  margin: 0;
  font-family: 'Roboto', sans-serif;
}
  
  h5, h4 {
    color: #333; /* Darker text for headings */
  }
  
  .container {
    padding: 2rem; /* More padding for your container */
  }
  
  .button {
    margin: 0.5rem; /* Space buttons */
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  } /* für footer mittig */
  